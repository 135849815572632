<template>
  <BaseModalIntro
    v-if="showValue"
    :introTitle="'Funeral'"
    :showValue="showValue"
    :show="show"
    @close="$emit('close')"
    @doNotShowAgain="doNotShowAgain"
  >
    <template v-slot:contents>
      <h5>Overview</h5>
      <p>
        By setting out your wishes now, when the time comes your loved ones will
        know what you wanted and be spared from having to make difficult
        decisions. Just a few words in your Will can avoid any disagreements
        between those you leave behind about what funeral arrangements you might
        have preferred. In addition, by capturing your funeral wishes in writing
        you'll know that your requests will be honored.
      </p>
      <h5>Choices</h5>
      <p>
        You can specify whether you wish to be buried or cremated, what type of
        funeral service you would prefer and whether you wish for your organs to
        be donated. In addition you have the option of including personal wishes
        or instructions. There are many things to consider - Where would you
        like your ashes stored or scattered? Do you want an open or closed
        casket? Is there a charity that you would like donations made to on your
        behalf? - you can add as little or as much information as you like.
      </p>
    </template>
  </BaseModalIntro>
</template>

<script>
import BaseModalIntro from '@/common/ui/BaseModalIntro'

export default {
  name: 'FuneralIntroModal',
  props: ['show'],
  components: {
    BaseModalIntro
  },
  computed: {
    showValue() {
      return this.$store.getters.showIntroModals.funeral
    }
  },
  methods: {
    doNotShowAgain() {
      this.$store.commit('showIntroModals', { funeral: false })
    }
  }
}
</script>

<style></style>
