<template>
  <NavQuestion
    :heading="heading"
    :number="'1'"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputOptionSmall
        :letter="'a'"
        :option="'Buried'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-funeral-burial.png'"
        :selected="funeral.funeral_finish === 'Buried'"
        @selected="save('Buried')"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'b'"
        :option="'Cremated'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-funeral-cremation.png'"
        :selected="funeral.funeral_finish === 'Cremated'"
        @selected="save('Cremated')"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'c'"
        :option="'No Preference'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-funeral-preference.png'"
        :selected="funeral.funeral_finish === 'No Preference'"
        @selected="save('No Preference')"
      ></InputOptionSmall>
      <IntroModal
        v-show="show.intro"
        :show="show.intro"
        @close="show.intro = false"
      ></IntroModal>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="'/home'"
        v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputOptionSmall from '@/common/ui/InputOptionSmall'
import IntroModal from '../IntroModal'

export default {
  name: 'FuneralType',
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputOptionSmall,
    IntroModal
  },
  computed: {
    funeral() {
      return this.$store.getters.funeral
    },
    isValidated() {
      if (this.funeral.funeral_finish) return true
      return false
    },
    heading() {
      return 'Would you prefer to be buried or cremated?'
    },
    subHeading() {
      return 'By documenting a preference in your Will, your executors job is made easier.'
    },
    forwardTo() {
      return '/funeral/style'
    }
  },
  data() {
    return {
      show: {
        intro: true
      }
    }
  },
  methods: {
    save(value) {
      if (this.funeral) {
        this.$store.commit('funeral', {
          funeral_finish: value
        })
      }
    }
  }
}
</script>
